import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
import { graphql } from 'gatsby';
import { IoMdBed, GiCastle, MdBook, MdMap } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import LatestArticles from '../sections/LatestArticles';
import Presentation from '../sections/Presentation';
import { Routes } from '../routes';
export const query = graphql`
  query {
    bnb: file(
      relativePath: { eq: "2020/04/airbnb-chambres-dhotes-nancy.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    castle: file(relativePath: { eq: "chateau_de_morey.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hall: file(
      relativePath: { eq: "location_de_salles_entre_nancy_et_metz.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hobby: file(
      relativePath: { eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Presentation langKey={props.pageContext.langKey} mdxType="Presentation" />
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          <strong>Bed and Breakfast</strong> tussen <strong>Nancy</strong> en <strong>Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
            Tussen <strong>Nancy</strong> en <strong>Metz</strong>, aan de rand van het bos, voor een uitzonderlijke tussenstop. Breng een nacht of een weekend door in dit 16e eeuwse kasteel, gebouwd op de ruïnes van een Tempeliersklooster. Een unieke en authentieke plek met een rijke geschiedenis.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            De familie Karst verwelkomt u in een van de 5 charmante gastenkamers. Wandel naar het geluid van de vogels in het park met eeuwenoude bomen.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Uitzonderlijk panoramisch uitzicht op de Natagne vallei. Buitenzwembad, minigolf, tafelvoetbal, biljart, jeu de boulesbaan. Afgesloten en beveiligde parkeerplaats. SPA en Massage op reservering.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.nl.BNB} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><IoMdBed mdxType="IoMdBed" /></SmallSpace>
            Kamers
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.bnb?.childImageSharp?.gatsbyImageData} alt="Bed and Breakfast in de buurt van Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Tertiary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">Le Château de Morey</Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          In het hart van Lotharingen, in een gerenoveerd 16e eeuws kasteel
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            De voormalige residentie van de hertogen van Lotharingen in de 16e eeuw, dit kasteel werd in 1985 volledig verwoest door een brand. Het werd herboren uit de as met de KARST-familie.  Rust en kalmte verzekerd. Gemakkelijke toegang, 5 km van de A31 afslag 24.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Rondom deze atypische bed and breakfast kunt u de stad Nancy bezoeken die op slechts 18 km afstand ligt en de vele monumenten en musea die de geschiedenis van deze stad door de eeuwen heen vertellen of Metz en het beroemde Pompidou centrum.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.nl.CASTLE} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><GiCastle mdxType="GiCastle" /></SmallSpace>
            Le Château
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.castle?.childImageSharp?.gatsbyImageData} alt="Authentiek kasteel Lorrain bij Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          Verhuur van zalen in Lotharingen tussen <strong>Nancy</strong> en <strong>Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
            Maak van uw evenement in een authentiek kader een onvergetelijk moment. Geopend sinds 1998, verhuren wij onze zalen voor bruiloften, seminaries, verjaardagen, cocktails etc... Verhuur van 3 zalen, keuken voor de traiteur, deel van het park met onderdak voor wijn van eer.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Als u op zoek bent naar een trouwlocatie in Lotharingen, in de buurt van Nancy en Metz. Als u een ideale omgeving wilt om van uw evenement een onvergetelijk moment te maken! Kom en ontdek het Château de Morey tussen Nancy en Metz stad.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Het is een magische plek, voor een onvergetelijke bruiloft! Het Château de Morey is een uitzonderlijke plek om de mooiste dag van uw leven te organiseren.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Een 16e eeuws kasteel ter decoratie met een tuin met eeuwenoude bomen. Op een prachtig bewaard gebleven en onderhouden terrein kan het kasteel van Morey worden ontdekt aan het einde van een majestueus ruiterpad.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.nl.SPACE_RENTAL} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdBook mdxType="MdBook" /></SmallSpace>
            Bekijk meer
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hall?.childImageSharp?.gatsbyImageData} alt="Verhuur van kamers in de buurt van Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <LatestArticles mdxType="LatestArticles" />
    <Section type={SectionType.Primary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
            Vrije tijd op het kasteel en ontdekking van <strong>Lotharingen</strong> !
        </Heading>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Lotharingen bezoeken, reizen, is jezelf laten verrassen en ontroeren door de ander en elders. En het is niet altijd nodig om naar het einde van de wereld te gaan...
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.nl.HOBBY} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdMap mdxType="MdMap" /></SmallSpace>
            Ontdek
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hobby?.childImageSharp?.gatsbyImageData} alt="Plaats stanislas Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      